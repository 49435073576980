<template>
	<main class="page-container container">
		<block-wrap class="mb-20" tag="header" offset-x="20" width-auto mod-style="justify-between align-center">
			<div>
				<app-text class="mb-8" :size="isMobileSmall ? 22 : 26" line-height="34" weight="600" tag="h1"
					>№ лота: {{ this.data.detail.lotNumber }}
				</app-text>
				<app-text size="14" line-height="18" tag="h2"
					>Категория: {{ this.data.detail.categoryName | translate }}
				</app-text>
			</div>
			<div class="auction-status d-flex" :class="isMobileMedium ? 'flex-wrap' : ''">
				<div class="auction-status__item">
					<app-text class="color-text-light mb-4" size="12" line-height="16"
						>{{ $t("commissionFee") }}:
					</app-text>
					<app-text class="color-text-danger" max-lines="1" size="14" line-height="18" weight="600"
						>{{ data.detail.comissionSum | toFix | moneyFormat }} UZS
					</app-text>
				</div>
				<div class="auction-status__item">
					<app-text class="color-text-light mb-4" size="12" line-height="16">
						{{ $t("pledgeAmount") }}:
					</app-text>
					<app-text max-lines="1" size="14" line-height="16" weight="600"
						>{{ data.detail.pledgeSum | toFix | moneyFormat }} UZS
					</app-text>
				</div>
				<div class="auction-status__item">
					<app-text class="color-text-light mb-4" size="12" line-height="16">Общая сумма:</app-text>
					<app-text class="color-text-success" max-lines="1" size="14" line-height="18" weight="600"
						>{{ data.detail.holdSum | toFix | moneyFormat }} UZS
					</app-text>
				</div>
			</div>
		</block-wrap>
		<div class="auction-table-wrap mb-30">
			<table class="auction-table">
				<thead>
					<tr>
						<th v-for="head in auctionHead" :key="head">{{ head }}</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="info in data.productList" :key="info.id">
						<td width="400">{{ info.productName | translate }}</td>
						<td width="100">{{ info.quantity }}</td>
						<td width="90">{{ info.measure }}</td>
						<td>
							<!-- <AppTextDescription :description="info.specification" /> -->
							{{ info.specification }}
						</td>
						<td class="fw-600">{{ info.price | moneyFormat }}</td>
						<td width="120">{{ data.inform.deliveryDay }}</td>
						<td>{{ data.inform.address }}</td>
					</tr>
				</tbody>
			</table>
		</div>

		<div class="mb-30">
			<app-content-card padding-x="0" padding-y="0" :radius="[8, 8, 0, 0]">
				<app-tabs v-model="selectedTab" :list="tabItems" />
			</app-content-card>

			<template v-if="selectedTab === 'lotInfo'">
				<div class="table-block mt-30">
					<table class="tab-table">
						<tbody>
							<tr>
								<td>Первый и последний день аукциона</td>
								<td>
									{{ data.inform.beginDate | moment("DD.MM.YYYY HH:mm:ss") }} /
									{{ data.inform.endDate | moment("DD.MM.YYYY HH:mm:ss") }}
								</td>
							</tr>
							<!-- <tr>
										  <td>Источник финансирования</td>
										  <td>{{ $options.filters.translate(data.inform.sourceFinanceName) }}</td>
						</tr>-->
							<tr>
								<td>Особые условия</td>
								<td>
									<AppTextDescription
										:isHtml="true"
										:description="data.inform.specialConditionList"
									/>
								</td>
							</tr>
							<tr>
								<td>Условие поставки товара</td>
								<td>{{ $options.filters.translate(data.inform.deliveryConditionName) }}</td>
							</tr>
							<tr>
								<td>Срок поставки товара</td>
								<td>{{ data.inform.deliveryDay }}</td>
							</tr>
							<tr>
								<td>Регион поставки</td>
								<td>{{ $options.filters.translate(data.inform.regionName) }}</td>
							</tr>
							<tr>
								<td>Район поставки</td>
								<td>{{ $options.filters.translate(data.inform.districtName) }}</td>
							</tr>
							<tr>
								<td>Адрес поставки</td>
								<td>{{ data.inform.address }}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</template>
			<template v-if="selectedTab === 'customerInfo'">
				<div class="table-block mt-30">
					<table class="tab-table">
						<tbody>
							<tr>
								<td>ИНН</td>
								<td>{{ data.inform.tin }}</td>
							</tr>
							<tr>
								<td>Наименование</td>
								<td>{{ data.inform.companyName | translate }}</td>
							</tr>
							<tr>
								<td>Регион</td>
								<td>{{ data.inform.companyRegionName | translate }}</td>
							</tr>
							<tr>
								<td>Район</td>
								<td>{{ data.inform.companyDistrictName | translate }}</td>
							</tr>
							<tr>
								<td>Адрес</td>
								<td>{{ data.inform.companyAddress }}</td>
							</tr>
							<tr>
								<td>Телефон</td>
								<td>{{ data.inform.phones }}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</template>
		</div>

		<div class="auction-footer-info flex-wrap mb-60">
			<div class="nd_lot_hour">
				<a style="cursor: pointer" @click="openModal()">
					<div class="auction-footer-info__name">
						<img
							class="mr-10"
							src="@/assets/images/time-control-tool.svg"
							:alt="$t('orderGlass')"
							width="24"
							height="24"
						/>
						<app-text class="font_Hsemibold bottom-line" size="14" line-height="16" weight="600"
							>{{ $t("orderGlass") }}
						</app-text>
					</div>
				</a>
			</div>

			<div class="d-flex align-center">
				<div class="auction-footer-info__item">
					<div class="d-flex flex-column align-center">
						<div class="d-flex">
							<div class="clock-item">
								<div class="d-flex flex-column align-center">
									<div class="clock-box">{{ days }}</div>
									<app-text
										class="color-text mt-5"
										max-lines="1"
										size="9"
										line-height="10"
										weight="500"
										>ДНЕЙ
									</app-text>
								</div>
								<span class="dots">:</span>
							</div>
							<div class="clock-item">
								<div class="d-flex flex-column align-center">
									<div class="clock-box">{{ hours }}</div>
									<app-text
										class="color-text mt-5"
										max-lines="1"
										size="9"
										line-height="10"
										weight="500"
										>ЧАСОВ
									</app-text>
								</div>
								<span class="dots">:</span>
							</div>
							<div class="clock-item">
								<div class="d-flex flex-column align-center">
									<div class="clock-box">{{ minutes }}</div>
									<app-text
										class="color-text mt-5"
										max-lines="1"
										size="9"
										line-height="10"
										weight="500"
										>МИНУТ
									</app-text>
								</div>
								<span class="dots">:</span>
							</div>
							<div class="clock-item">
								<div class="d-flex flex-column align-center">
									<div class="clock-box">{{ seconds }}</div>
									<app-text
										class="color-text mt-5"
										max-lines="1"
										size="9"
										line-height="10"
										weight="500"
										>СЕКУНД
									</app-text>
								</div>
							</div>
						</div>
						<!-- <app-text class="color-text" size="14" line-height="18"
								  >{{ days }}&emsp;:&emsp;{{ hours }}&emsp;:&emsp;{{ minutes }}&emsp;:&emsp;{{ seconds }}</app-text
						>-->
						<!-- <app-text
						  class="color-text mt-5"
						  max-lines="1"
						  size="9"
						  line-height="10"
						  weight="500"
						>дней&emsp;Часов&emsp;Минут&emsp;секунд</app-text>-->
					</div>
				</div>
				<div class="vert-line"></div>

				<div class="auction-footer-info__item">
					<app-text class="color-text-light" size="14" line-height="18">Стартовая сумма</app-text>
					<app-text class="color-text-danger" max-lines="1" size="16" line-height="21" weight="600">
						{{ data.detail.lotSum | moneyFormat }}
					</app-text>
				</div>
				<div class="vert-line"></div>

				<div class="auction-footer-info__item">
					<app-text class="color-text-light" size="14" line-height="18">Текущая сумма</app-text>
					<app-text max-lines="1" size="16" line-height="21" weight="600">
						{{ data.detail.currentPrice | moneyFormat }}
					</app-text>
				</div>

				<div class="auction-footer-info__item next-price">
					<div class="color-text-success d-flex flex-column align-center">
						<app-text size="14" line-height="18" class="mb-5">Следующая цена</app-text>
						<div class="d-flex w-auto">
							<template v-if="data.detail.stepList.length < 2">
								<app-text max-lines="1" line-height="21" weight="600">
									{{ data.detail.nextPrice | moneyFormat }}
								</app-text>
							</template>

							<template v-else>
								<div class="nd_dropdown_new">
									<!-- <div class="nd_dropdown_icon">
														<img src="../../../assets/images/arrow1.png" alt="">
									</div>-->
									<!--							<div class="nd_dropdown_abs">-->
									<!--								<div v-for="item in data.detail.stepList" class="nd_dropdown_item">-->
									<!--									{{item.price | moneyFormat}}-->
									<!--								</div>-->

									<!--							</div>-->
									<select v-model="selectedStep" class="select_new">
										<option
											v-for="(item, idx) in data.detail.stepList"
											:key="idx"
											:value="item.step"
										>
											{{ item.price | moneyFormat }} ({{ item.step }} шаг)
										</option>
									</select>
									<img src="../../../assets/images/arrow1.png" alt />
								</div>
							</template>
						</div>
					</div>
				</div>
			</div>

			<div class="auction-footer-info__final">
				<!--				<app-button theme="linear" height="40" sides="30" font-size="14" class="bag_color_gradient">Подать-->
				<!--					заявку-->
				<!--				</app-button>-->

				<template v-if="allSeconds > 0">
					<template v-if="canSendBid">
						<app-button
							v-if="data.detail.applicationConfirmed"
							@click="selectEsp()"
							:loading="loading"
							padding-x="30"
							font-weight="600"
							theme="linear"
							height="40"
							sides="30"
							font-size="14"
							class="bag_color_gradient ml-auto"
							>Подать заявку
						</app-button>
						<!--        disabled-->
						<app-button
							v-else
							padding-x="30"
							font-weight="600"
							@click="toggleModal"
							theme="linear"
							height="40"
							sides="30"
							font-size="14"
							class="bag_color_gradient ml-auto"
							>Подать заявку
						</app-button>
					</template>

					<!--					<template v-else>-->
					<!--						<app-button-->
					<!--							style="opacity: 0.5;"-->
					<!--							padding-x="30"-->
					<!--							font-weight="600"-->
					<!--							theme="linear" height="40" sides="30" font-size="14" class="bag_color_gradient ml-auto"-->
					<!--						>Подать заявку-->
					<!--						</app-button>-->
					<!--					</template>-->
				</template>
			</div>
		</div>

		<AppModal
			ref="myChild"
			class="pa-0"
			v-if="isModalShow"
			v-model="isModalShow"
			max-width="538"
			:closeButton="false"
		>
			<div class="modal-hold-block">
				<div class="modal__holder">
					<div class="label-input-group d-flex justify-space-between">
						<app-text size="18" weight="600">История подачи ставок</app-text>
						<div>
							<button @click="$refs.myChild.close()">
								<img src="../../../assets/images/Unionx.svg" alt />
							</button>
						</div>
					</div>
					<div class="modal-body pt-30 flex-1">
						<div class="table-block modal-table" style="max-height: 490px">
							<table>
								<thead>
									<tr>
										<th>{{ $t("price") }}</th>
										<th>Снижение</th>
										<th>Дата</th>
										<th>Время</th>
									</tr>
								</thead>

								<tbody>
									<tr
										v-for="order in result"
										:key="order.id"
										:class="{ selected_table_item: order.isSelected }"
									>
										<td class="text-center">{{ order.bidPrice | moneyFormat }}</td>
										<td class="text-center">{{ order.stepPercent }} %</td>
										<td class="text-center">{{ order.bidTime.slice(0, 10) }}</td>
										<td class="text-center">{{ order.bidTime.slice(11, 19) }}</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
					<div class="modal-footer mt-30">
						<app-button
							@click="closeModal"
							theme="linear"
							height="40"
							sides="20"
							font-size="14"
							class="font_Msemibold"
							>{{ $t("close") }}
						</app-button>
					</div>
				</div>
			</div>
		</AppModal>

		<AuctionRequestDialog
			:lotId="data.detail.lotId"
			v-if="data.productList"
			:nextPrice="data.detail.nextPrice"
			:lotSum="data.detail.lotSum"
			ref="autctionRequestDialogRef"
			@saved="onAuctionSaved"
		/>
	</main>
</template>
<script>
import BlockWrap from "../../../components/shared-components/BlockWrap";
import AppText from "../../../components/shared-components/AppText";
import DynamicIcon from "../../../components/shared-components/DynamicIcon";
import AppButtonA from "../../../components/shared-components/AppButtonA";
import Modal from "@/components/shared-components/Modal";
import AppModal from "../../../components/shared-components/AppModal";
import AppRadioGroup from "../../../components/shared-components/AppRadioGroup";
import AppButton from "../../../components/shared-components/AppButton";
import AuctionRequestDialog from "../../../components/pages/auction/AuctionRequestDialog.vue";
import jwtDecode from "jwt-decode";
import TokenService from "@/services/TokenService";
import AppContentCard from "../../../components/shared-components/AppContentCard";
import AppTabs from "../../../components/shared-components/AppTabs";
import AppTextDescription from "../../../components/shared-components/AppTextDescription";
import VueDragResize from "vue-drag-resize";
import { EIMZOClient } from "../../../eimzo/e-imzo-client";

const signalR = require("@aspnet/signalr");

let EIMZO_MAJOR = 3;
let EIMZO_MINOR = 37;
let errorCAPIWS = "Ошибка соединения с E-IMZO. Возможно у вас не установлен модуль E-IMZO или Браузер E-IMZO.";
let errorBrowserWS = "Браузер не поддерживает технологию WebSocket. Установите последнюю версию браузера.";
let errorUpdateApp =
	'ВНИМАНИЕ !!! Установите новую версию приложения E-IMZO или Браузера E-IMZO.<br /><a href="https://e-imzo.uz/main/downloads/" role="button">Скачать ПО E-IMZO</a>';
let errorWrongPassword = "Пароль неверный.";

String.prototype.replaceAll = function (search, replace) {
	return this.split(search).join(replace);
};

let info = function () {
	let itm = document.testform.key.value;
	if (itm) {
		let id = document.getElementById(itm);
		let vo = JSON.parse(id.getAttribute("vo"));
		let alias = vo.alias;
		alias = alias.replaceAll(",", '","').replaceAll("=", '":"');
		let str = '{"' + alias + '"}';
		let obj = JSON.parse(str);
		if (obj.t === "direktor") alert("Director");
		else alert("Not director");
	}
};
let AppLoad = function (_this) {
	EIMZOClient.API_KEYS = [
		"localhost",
		"96D0C1491615C82B9A54D9989779DF825B690748224C2B04F500F370D51827CE2644D8D4A82C18184D73AB8530BB8ED537269603F61DB0D03D2104ABF789970B",
		"127.0.0.1",
		"A7BCFA5D490B351BE0754130DF03A068F855DB4333D43921125B9CF2670EF6A40370C646B90401955E1F7BC9CDBF59CE0B2C5467D820BE189C845D0B79CFC96F",
		"null",
		"E0A205EC4E7B78BBB56AFF83A733A1BB9FD39D562E67978CC5E7D73B0951DB1954595A20672A63332535E13CC6EC1E1FC8857BB09E0855D7E76E411B6FA16E9D",
		"new.cooperation.uz",
		"EA199679060553718E35DF32D0E30D369883D80C0878D6EBB8FDBB9AF2135B675A0408058A67840B81C26DC78C6736D0BDC25FC775802944D75D7AF04D210247",
	];
	EIMZOClient.checkVersion(
		function (major, minor) {
			let newVersion = EIMZO_MAJOR * 100 + EIMZO_MINOR;
			let installedVersion = parseInt(major) * 100 + parseInt(minor);
			if (installedVersion < newVersion) {
				uiUpdateApp();
			} else {
				EIMZOClient.installApiKeys(
					function () {
						uiLoadKeys(_this);
					},
					function (e, r) {
						if (r) {
							uiShowMessage(r);
						} else {
							wsError(e);
						}
					}
				);
			}
		},
		function (e, r) {
			if (r) {
				uiShowMessage(r);
			} else {
				uiNotLoaded(e);
			}
		}
	);
};
let uiShowMessage = function (message) {
	alert(message);
};
let uiNotLoaded = function (e) {
	let l = document.getElementById("message");
	l.innerHTML = "";
	if (e) {
		wsError(e);
	} else {
		uiShowMessage(errorBrowserWS);
	}
};
let uiUpdateApp = function () {
	let l = document.getElementById("message");
	l.innerHTML = errorUpdateApp;
};
let uiLoadKeys = function (_this) {
	EIMZOClient.listAllUserKeys(
		function (o, i) {
			return "itm-" + o.serialNumber + "-" + i;
		},
		function (itemId, v) {
			_this.addOpt(itemId, v);
			return null;
		},
		function (items, firstId) {
			return "";
		},
		function (e, r) {
			//uiShowMessage(errorCAPIWS);
		}
	);
};
let wsError = function (e) {
	if (e) {
		uiShowMessage(errorCAPIWS + " : " + e);
	} else {
		uiShowMessage(errorBrowserWS);
	}
};

export default {
	props: ["id"],
	components: {
		AppTextDescription,
		AppTabs,
		AppContentCard,
		AppButton,
		AppRadioGroup,
		AppModal,
		AppButtonA,
		DynamicIcon,
		AppText,
		BlockWrap,
		VueDragResize,
		Modal,
		AuctionRequestDialog,
	},
	data() {
		return {
			token: TokenService.getToken(),
			hash: null,
			datakeys: [],
			selectData: {
				id: 0,
				name: "",
				tin: "",
				businessCategory: "",
				region: "",
				district: "",
				dSKeyNumber: "",
				dSKeySerial: "",
				telefon: "",
				email: "",
				confirmPassword: "",
				userLastName: "",
				userFirstName: "",
				userMiddleName: "",
				userPosition: "",
				userInn: "",
			},

			width: 0,
			height: 0,
			top: -50,
			left: 0,
			orgId: null,
			cleanIntervalCode: null,
			loading: false,
			auctionHead: ["Наименование", "Кол-во", "ед.Изм.", "Описание", "Цена", "Срок доставки", "Адрес"],
			result: null,
			allSeconds: 0,
			selectedStep: 1,
			data: {
				detail: {
					id: null,
					lotNumber: null,
					categoryName: {
						ru: null,
					},
					comissionSum: null,
					bidCount: null,
					pledgeSum: null,
					days: null,
					hours: null,
					minutes: null,
					seconds: null,

					applicationConfirmed: null,
					authorized: null,
					stepList: [],
				},
				inform: {},
			},
			isModalShow: false,
			hubConnection: null,
			selectedTab: "lotInfo",
			tabItems: [
				{
					name: "ИНФОРМАЦИЯ О ЛОТЕ",
					value: "lotInfo",
				},
				{
					name: "ИНФОРМАЦИЯ О ЗАКАЗЧИКЕ",
					value: "customerInfo",
				},
			],
		};
	},
	computed: {
		canSendBid() {
			let orgId = this.orgId;
			let data = this.data;
			return orgId && data.detail.bidCompanyId !== orgId && data.detail.lotCompanyId !== orgId;
		},
		days() {
			let allSeconds = this.allSeconds;
			return Math.floor(allSeconds / 86400);
		},
		hours() {
			let allSeconds = this.allSeconds;
			return Math.floor((allSeconds % 86400) / 3600);
		},
		minutes() {
			let allSeconds = this.allSeconds;
			return Math.floor(allSeconds / 60) % 60;
		},
		seconds() {
			let allSeconds = this.allSeconds;
			return Math.floor(allSeconds % 60);
		},
	},
	async mounted() {
		AppLoad(this);
		await this.load();
		this.connectSocket();
		this.checkInfo();
	},
	beforeDestroy() {
		this.cleanTimerInterval();
	},
	methods: {
		selectEsp(val = this.$store.state.userInfo.tin) {
			if (!val) {
				this.$notification.error("Не найден ИНН");
				return;
			}
			let _this = this;
			let key = this.datakeys.find((item) => item.TIN === val);
			if (!key) {
				this.$notification.error("Не найден ключ");
				return;
			}
			let jsonData = JSON.stringify(this.data.detail);
			this.getKey(key).then((response) => {
				this.chekPassword(response, jsonData).then((response) => {
					_this.hash = response;
					this.passApplication(key);
				});
			});
		},
		addOpt(e, v) {
			let alias = v.alias; //this.datakeys[val].alias;
			alias = alias.replaceAll(",", '","').replaceAll("=", '":"');
			let str = '{"' + alias + '"}';
			let obj = JSON.parse(str);
			this.datakeys.push(v);
		},

		getKey(item) {
			return new Promise((resolve, reject) => {
				EIMZOClient.loadKey(item, function (val) {
					resolve(val);
				});
			});
		},

		chekPassword(keyId, jsData) {
			let _this = this;
			return new Promise((resolve, reject) => {
				EIMZOClient.createPkcs7(
					keyId,
					"salom dunyo",
					null,
					function (val) {
						resolve(val);
					},
					function (err) {
						_this.$notification.error("пароль введен неправильно");
					}
				);
			});
		},

		resize(newRect) {
			this.width = newRect.width;
			this.height = newRect.height;
			this.top = newRect.top;
			this.left = newRect.left;
		},
		reRenderData(data = {}) {
			this.data.detail = { ...this.data.detail, ...data };
			this.data = { ...this.data };
			this.selectedStep = 1;
			if (this.data?.detail?.allSeconds >= 0) {
				this.allSeconds = this.data.detail.allSeconds;
			}
		},
		checkInfo() {
			if (this.token) {
				try {
					this.orgId = +jwtDecode(this.token).OrgId;
				} catch (e) {
					console.log(e);
				}
			}
		},

		connectSocket() {
			let tokenString = this.token;
			if (!tokenString) {
				return;
			}
			this.hubConnection = new signalR.HubConnectionBuilder()
				.withUrl(`${process.env.VUE_APP_SOCKET_URL}`, {
					accessTokenFactory: () => tokenString,
				})
				.build();
			// connecting to the hub
			this.hubConnection
				.start()
				.then(() => {
					let lotId = this.data.detail.lotId;
					this.hubConnection
						.invoke("FirstLoad", lotId)
						.then((response) => {
							console.log(response, "invoke response");
						})
						.catch((err) => {
							console.log(err, "invoke err");
						});
				})
				.catch((err) => console.log("connecting hub failed err is : ", err));

			this.hubConnection.on("GetAuction", (data) => {
				this.reRenderData(data);
				if (this.isModalShow) {
					this.getOrderBook();
				}
			});
		},
		cleanTimerInterval() {
			clearInterval(this.cleanIntervalCode);
		},
		async onAuctionSaved() {
			await this.load();
			this.selectEsp();
		},
		runTimer() {
			if (this.allSeconds > 0 && !this.cleanIntervalCode) {
				this.cleanIntervalCode = setInterval(() => {
					if (this.allSeconds <= 0) {
						this.cleanTimerInterval();
					} else {
						--this.allSeconds;
					}
				}, 1000);
			}
		},
		async load() {
			await this.$api
				.get(`/api-auction/Provider/GetAuctionLotDetails`, {
					params: { lotNumber: this.id },
				})
				.then(
					(response) => {
						const res = response.data;
						if (res && res.result) {
							this.data = res.result;
							this.total = res.result.total;
							this.data.detail.id = res.result.detail.id;
							if (this.data.detail.allSeconds >= 0) {
								this.allSeconds = this.data.detail.allSeconds;
							}
							this.data.inform.specialConditionList = this.data.inform.specialConditionList
								.map((item) => {
									return this.$options.filters.translate(item);
								})
								.join(". </br></br>");

							this.runTimer();
						} else {
							this.$notification.error(res.error.message);
						}
					},
					(error) => {
						this.$notification.error(error);
					}
				)
				.finally(() => {
					this.loading = false;
				});

			await this.getOrderBook();
		},
		getOrderBook() {
			return this.$api
				.get(`/api-auction/Provider/OrderBook`, {
					params: { lotId: this.data.detail.lotId || null },
				})
				.then((response) => {
					const res = response.data;
					if (res && res.result) {
						this.result = res.result;
					} else {
						this.$notification.error(res.error.message);
					}
				})
				.finally(() => {
					this.loading = false;
				});
		},
		passApplication() {
			let model = {
				id: this.data.detail.id,
				isMedical: this.data.detail.isMedical,
				step: this.selectedStep,
				signature: this.hash,
			};
			this.$api
				.put(`/api-auction/Provider/BidSubmit`, model)
				.then((response) => {
					this.loading = true;
					const res = response.data;
					if (res && res.result) {
						this.$notify({ type: "success", text: "Заявка отправлен!" });
					} else {
						this.$notification.error(res.error.message);
					}
				})
				.finally(() => {
					this.loading = false;
				});
		},
		openModal() {
			this.isModalShow = true;
			this.getOrderBook();
		},
		closeModal() {
			//   this.isModalShow = false;
			this.$refs.myChild.close();
			setTimeout(this, 2000);
		},
		toggleModal() {
			this.$refs.autctionRequestDialogRef.open(this.data.productList);
		},
	},
};
</script>
<style lang="scss" scoped>
@import "../../../assets/styles/mixins";

.history-applications-table {
	min-width: 100%;
	border-spacing: 0;

	th {
		background: #f4f8fb;
		font-weight: bold;
		font-size: 14px;
		line-height: 16px;
		padding: 0 20px;
		height: 60px;
		color: #353c47;
		border: 0.1px solid black;
		border-collapse: collapse;

		&:first-child {
			border-radius: 7px 0 0 0;
		}

		&:last-child {
			border-right: 0;
			border-radius: 0 7px 0 0;
		}
	}
}

.table-block {
	.tab-table {
		tr {
			td {
				width: 50%;
				padding: 19px 20px;

				&:first-child {
					font-weight: 600;
				}
			}
		}
	}
}

.auction-table {
	min-width: 100%;
	border-spacing: 0;

	th {
		background: #1faec1;
		font-weight: 500;
		font-size: 12px;
		line-height: 16px;
		padding: 0 20px;
		height: 60px;
		color: #fff;
		text-transform: uppercase;
		border-right: 1px solid rgba(69, 186, 203);

		&:first-child {
			border-radius: 8px 0 0 0;
			text-align: left;
		}

		&:last-child {
			border-right: 0;
			border-radius: 0 8px 0 0;
		}
	}

	td {
		padding: 14px 20px;
		height: 63px;
		min-width: 90px;
		text-align: center;
		border-bottom: 1px solid #dfeaf3;
		border-right: 1px solid #dfeaf3;
		font-size: 14px;

		&:first-child {
			border-left: 1px solid #dfeaf3;
			background-color: #f3f8fc;
			text-align: left;
			font-weight: 600;
		}
	}

	tr:last-child {
		td {
			&:first-child {
				border-radius: 0 0 0 8px;
			}

			&:last-child {
				border-radius: 0 0 8px 0;
			}
		}
	}

	&__description {
		min-width: 200px !important;
	}

	&-wrap {
		overflow-x: auto;
	}
}

.auction-status {
	background: #fafdff;
	border: 1px solid #dfeaf3;
	border-radius: 8px;
	width: 536px;

	&__item {
		text-align: center;
		width: calc(100% / 3);
		flex-grow: 1;
		padding: 13px 15px;
		position: relative;

		&:after {
			content: "";
			width: 15px;
			height: 100%;
			background: url("../../../assets/images/auction-delimiter.svg") center/15px 100% no-repeat;
			position: absolute;
			right: 0;
			top: 0;
			bottom: 0;
		}

		&:last-child {
			&:after {
				display: none;
			}
		}
	}
}

.modal__holder {
	/*padding: 0;*/
	display: flex;
	flex-direction: column;
}

.resize-modal-header {
	background-color: #7c579e;
	color: white;
	cursor: pointer;
}

.drag-modal-container {
	/*.content-container{
		  height: auto !important;
	  }*/
	.vdr.active:before {
		outline: none;
	}

	.modal__content {
		position: absolute;
		bottom: 0;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		pointer-events: all;
	}

	.overlay {
		background-color: inherit;
		display: none !important;
	}

	.vdr-stick {
		border-style: none;
		background: inherit;
	}

	//	top: -200px;

	.modal__close {
		display: none;
	}
}

.modal-hold-block,
.modal__holder {
	height: 100%;
}

.auction-footer-info {
	background: #fafdff;
	border: 1px solid #dfeaf3;
	border-radius: 8px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	//overflow: hidden;

	&__item {
		text-align: center;
		flex-grow: 1;
		padding: 10px 50px;
		// border-right: 1px solid #dfeaf3;
		&:last-child {
		}

		&.next-price {
			border-left: 1px solid #dfeaf3;
		}
	}

	&__name {
		display: flex;
		align-items: center;
		justify-content: center;
		background: #1faec1;
		margin-left: -1px;
		margin-top: -1px;
		margin-bottom: -1px;
		width: 265px;
		height: 70px;
		color: #fff;
	}

	&__final {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		// border-left: 1px solid #dfeaf3;
		margin-left: -1px;
		text-align: center;
		// width: #{size(406px, 1260px)};
		padding-right: 20px;
		height: 68px;
		min-width: 350px;
	}
}

.nd_lot_hour {
	border-top-left-radius: 8px;
	border-bottom-left-radius: 8px;
	overflow: hidden;
}

.modal-table {
	max-height: 490px;
	//   overflow-y: scroll;
}

.drag-modal-container {
	pointer-events: none;
}

.nd_dropdown_icon {
	width: 20px;
	height: 20px;
	background: #ffffff;
	box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05);
	border-radius: 3px;
}

.nd_dropdown_abs {
	min-width: 220px;
	height: 0;
	overflow-y: auto;
	position: absolute;
	background: #ffffff;
	box-shadow: 0 4px 16px rgba(0, 0, 0, 0.06);
	border-radius: 8px;
	z-index: 3;
	top: 30px;
	right: 0;
	opacity: 0;
	transition: 0.3s;
}

.nd_dropdown_item {
	padding: 11px 20px;
	color: #353c47;
}

.nd_dropdown_item:hover {
	background: #f9fcff;
	cursor: pointer;
}

.nd_dropdown_new.active .nd_dropdown_abs {
	height: 240px;
	opacity: 1;
}

.nd_dropdown_new {
	position: relative;

	img {
		position: absolute;
		top: 9px;
		right: 10px;
		pointer-events: none;
	}
}

.select_new {
	padding: 7px 23px 7px 15px;
	border: 1px solid #dfeaf3;
	border-radius: 6px;
	-webkit-appearance: none;
	color: #27ae60;
}

.select_new:focus-visible {
	outline: none !important;
}

.app-tabs {
	&__item {
		padding: 20px 25px;
		margin-right: 25px;
	}
}

.clock-item {
	display: flex;

	.dots {
		margin: 0 5px;
		margin-top: 6px;
	}
}

.clock-box {
	width: 30px;
	height: 32px;

	&:first-child {
		margin-left: 0;
	}

	&:last-child {
		margin-right: 0;
	}
}

.modal-footer {
	display: flex;
	justify-content: flex-end;
}

@media (max-width: 600px) {
	.auction-status__item {
		width: calc(100% / 2);
	}

	.auction-table td {
		padding: 10px;
	}
}

@media (max-width: 450px) {
	.auction-status {
		padding: 10px 0;
	}

	.auction-status__item {
		width: calc(100% / 1);
		text-align: left;
		padding: 5px 15px;

		&:last-child {
			.color-text-success {
				margin-bottom: 0 !important;
			}
		}
	}

	.auction-footer-info__final {
		height: unset;
		padding: 15px 20px;
		flex-wrap: wrap;
		justify-content: center;
	}

	.color-text-success {
		width: 100%;
		margin-bottom: 15px;
	}
	.nd_lot_hour {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}
}
</style>
